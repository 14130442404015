/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TriggerCampaignReqDto {
	trigger: TriggerCampaignReqDto.TriggerEnum;
	recipient: object;
	campaignId: string;
}
export namespace TriggerCampaignReqDto {
	export type TriggerEnum = 'NEW_CONTACT' | 'NEW_MEETING' | 'MANUAL';
	export const TriggerEnum = {
		NewContact: 'NEW_CONTACT' as TriggerEnum,
		NewMeeting: 'NEW_MEETING' as TriggerEnum,
		Manual: 'MANUAL' as TriggerEnum,
	};
}
