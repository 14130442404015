import { TokenService } from '../services/token.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
	constructor(private tokenService: TokenService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.tokenService.token;
		let authRequest;

		if (token) {
			authRequest = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		} else {
			authRequest = request;
		}

		// Pass the cloned request to the next handler
		return next.handle(authRequest);
	}
}
