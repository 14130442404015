/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Link {
	id?: string;
	createdAt?: string;
	type?: Link.TypeEnum;
	userID: string;
	profile?: Link.ProfileEnum;
}
export namespace Link {
	export type TypeEnum = 'CARD' | 'PUBLIC';
	export const TypeEnum = {
		Card: 'CARD' as TypeEnum,
		Public: 'PUBLIC' as TypeEnum,
	};
	export type ProfileEnum = 'primary' | '0' | '1';
	export const ProfileEnum = {
		Primary: 'primary' as ProfileEnum,
		_0: '0' as ProfileEnum,
		_1: '1' as ProfileEnum,
	};
}
