/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Discount {
	uid?: string;
	type: Discount.TypeEnum;
	category: string;
	partner: string;
	partnerEmail?: string;
	disabled?: boolean;
	linkUrl?: string;
	logoUrl?: string;
	couponCode?: string;
	partnerMessage?: string;
	customerMessage?: string;
	index?: number;
	formFields?: Array<string>;
}
export namespace Discount {
	export type TypeEnum = 'link' | 'form' | 'coupon';
	export const TypeEnum = {
		Link: 'link' as TypeEnum,
		Form: 'form' as TypeEnum,
		Coupon: 'coupon' as TypeEnum,
	};
}
