/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Profile } from './profile';

export interface Contact {
	id?: string;
	savedProfile?: Contact.SavedProfileEnum;
	linkID?: string;
	firstName: string;
	lastName: string;
	profileURL?: string;
	tags: { [key: string]: boolean };
	createdAt: string;
	comment?: string;
	inactive?: boolean;
	isManual?: boolean;
	profile?: Profile;
}
export namespace Contact {
	export type SavedProfileEnum = 'primary' | '0' | '1';
	export const SavedProfileEnum = {
		Primary: 'primary' as SavedProfileEnum,
		_0: '0' as SavedProfileEnum,
		_1: '1' as SavedProfileEnum,
	};
}
