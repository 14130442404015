import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthsService } from '../../../services/auths.service';

@Directive({
	// eslint-disable-next-line
	selector: '[hasAnyAuthorityLevel]',
})
export class HasAnyAuthorityLevelDirective implements OnDestroy {
	private authorities!: string | string[];

	private readonly destroy$ = new Subject<void>();

	constructor(
		private authService: AuthsService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef
	) {}

	@Input()
	set hasAnyAuthorityLevel(value: string | string[]) {
		this.authorities = value;
		this.updateView();

		this.authService.authoritiesRefreshed.subscribe(() => {
			this.updateView();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateView(): void {
		const hasAnyAuthority = this.authService.hasAnyAuthority(this.authorities);

		this.viewContainerRef.clear();
		if (hasAnyAuthority) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}
}
