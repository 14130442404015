import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'cardinmove-web';
	isProd = environment.production;

	isMobileView = true;
	isMenuOpened = true;

	ngOnInit() {
		this.viewCheck();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.viewCheck();
	}

	openMenu() {
		this.isMenuOpened = true;
	}

	closeMenu() {
		if (window.innerWidth < 768) {
			this.isMenuOpened = false;
		}
	}

	private viewCheck() {
		this.isMobileView = window.innerWidth < 768;
		this.isMenuOpened = !this.isMobileView;
	}
}
