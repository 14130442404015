import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Link, LinksService, Profile, UsersService } from '../../../api';
import ProfileEnum = Link.ProfileEnum;
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-qr-modal',
	templateUrl: './qr-modal.component.html',
	styleUrls: ['./qr-modal.component.scss'],
})
export class QrModalComponent implements OnInit {
	content: string;
	public qrCodeDownloadLink: string | null;
	public imageUri = '';
	private fileName = '';

	constructor(
		private auth: AngularFireAuth,
		public dialogRef: MatDialogRef<QrModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { userId: string; profileIndex: ProfileEnum },
		private sanitizer: DomSanitizer,
		private userService: UsersService,
		private ts: TranslateService,
		private snackBar: MatSnackBar,
		private linkService: LinksService
	) {}

	async ngOnInit() {
		this.createFileName().then();
		const link = await firstValueFrom(
			this.linkService.linksControllerCreatePublicLink({
				userID: this.data.userId,
				profile: this.data.profileIndex,
			})
		);
		this.content = environment.viewProfileURL + link.id;
	}

	dismiss() {
		this.dialogRef.close();
	}

	async createFileName() {
		const profile: Profile = await firstValueFrom(
			this.userService.usersAdminControllerGetUserProfile(this.data.userId, ProfileEnum.Primary)
		);

		const email = profile.email ? profile.email.concat('_') : '';
		const firstName = profile.firstName ? profile.firstName : '';
		const lastName = profile.lastName ? profile.lastName : '';
		this.fileName = 'CIM_QRCode_' + email + firstName + lastName + '.png';
	}

	onChangeURL(url: SafeUrl) {
		this.qrCodeDownloadLink = this.sanitizer.sanitize(SecurityContext.URL, url);
	}

	async onDownloadClick() {
		await this.saveImage();
	}

	async saveImage() {
		try {
			console.log('save QR code');
			const a = document.createElement('a');
			a.href = this.qrCodeDownloadLink;
			a.download = this.fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);

			this.snackBar.open(this.ts.instant('qr.toast-saved'), 'X');
		} catch (error) {
			this.snackBar.open(this.ts.instant('qr.toast-error') + ' : ' + error, 'X', {
				panelClass: 'error',
			});
		}
	}

	async share() {
		// TODO: implement copy to clipboard link
	}
}
