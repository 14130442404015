/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';
import { Title } from './title';

export interface Profile {
	userId?: string;
	profileName?: string;
	profileId?: Profile.ProfileIdEnum;
	firstName?: string;
	lastName?: string;
	homeAddress?: string;
	cellPhone?: string;
	cellPhone2?: string;
	cellPhone3?: string;
	cellPhone4?: string;
	whatsapp?: string;
	email?: string;
	email2?: string;
	email3?: string;
	url?: string;
	birthday?: string;
	organization?: string;
	orgAddress?: string;
	orgTaxNumber?: string;
	orgRegistrationNumber?: string;
	languages?: Language;
	chatBot?: string;
	questionnaire?: string;
	title?: string;
	titleNew?: Title;
	fileURL?: string;
	fileType?: Profile.FileTypeEnum;
	fileName?: string;
	profileURL?: string;
	videoURL?: string;
	publicLink?: string;
	registrationLink?: string;
	publicProfileURL?: string;
	allowedLinkIDs?: Array<string>;
	facebook?: string;
	facebook_business?: string;
	youtube?: string;
	instagram?: string;
	instagram_business?: string;
	tiktok?: string;
	linkedIn?: string;
	linkedIn_business?: string;
	twitter?: string;
	snapchat?: string;
	flickr?: string;
	skype?: string;
	pinterest?: string;
	vimeo?: string;
	patreon?: string;
	imdb?: string;
	soundcloud?: string;
	spotify?: string;
	mnbNumber?: string;
	profileTheme?: string;
	notes?: string;
}
export namespace Profile {
	export type ProfileIdEnum = 'primary' | '0' | '1';
	export const ProfileIdEnum = {
		Primary: 'primary' as ProfileIdEnum,
		_0: '0' as ProfileIdEnum,
		_1: '1' as ProfileIdEnum,
	};
	export type FileTypeEnum =
		| 'CV'
		| 'PRODUCT_PRESENTATION'
		| 'COMPANY_INTRODUCTION'
		| 'RECOMMENDATION'
		| 'CERTIFICATE'
		| 'OTHER';
	export const FileTypeEnum = {
		Cv: 'CV' as FileTypeEnum,
		ProductPresentation: 'PRODUCT_PRESENTATION' as FileTypeEnum,
		CompanyIntroduction: 'COMPANY_INTRODUCTION' as FileTypeEnum,
		Recommendation: 'RECOMMENDATION' as FileTypeEnum,
		Certificate: 'CERTIFICATE' as FileTypeEnum,
		Other: 'OTHER' as FileTypeEnum,
	};
}
