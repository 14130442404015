/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CourseLesson } from './course-lesson';

export interface Course {
	id?: string;
	name: string;
	tags: Array<string>;
	imageURL?: string;
	lessons: Array<CourseLesson>;
	index: number;
}
