import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
	name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
	transform(value?: Date, format = 'YYYY-MM-DD'): unknown {
		return dayjs(value).format(format);
	}
}
