/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatLastRead } from './chat-last-read';

export interface Chat {
	id?: string;
	name?: string;
	members: Array<string>;
	lastReadMessage?: Array<ChatLastRead>;
	lastMessageId?: string;
}
