/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Payout {
	id?: string;
	userID: string;
	createdAt: string;
	rank: Payout.RankEnum;
	paid: boolean;
}
export namespace Payout {
	export type RankEnum = 'silver' | 'gold' | 'platinum';
	export const RankEnum = {
		Silver: 'silver' as RankEnum,
		Gold: 'gold' as RankEnum,
		Platinum: 'platinum' as RankEnum,
	};
}
