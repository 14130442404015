/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SubscriptionType {
	id?: string;
	name: string;
	isHidden?: boolean;
	isDeleted?: boolean;
	authorities: Array<string>;
	constraints?: object;
	paymentType: SubscriptionType.PaymentTypeEnum;
	iosProductId?: string;
	androidProductId?: string;
}
export namespace SubscriptionType {
	export type PaymentTypeEnum = 'FREE' | 'STORE' | 'ORDER' | 'DISABLED';
	export const PaymentTypeEnum = {
		Free: 'FREE' as PaymentTypeEnum,
		Store: 'STORE' as PaymentTypeEnum,
		Order: 'ORDER' as PaymentTypeEnum,
		Disabled: 'DISABLED' as PaymentTypeEnum,
	};
}
