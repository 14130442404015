import { Pipe, PipeTransform } from '@angular/core';

import { ProfileMatch, SocialLinks, TYPE_DESKTOP } from 'social-links';
import { facebook } from 'social-links/lib/profiles/facebook';
import { instagram } from 'social-links/lib/profiles/instagram';

const WebsiteMatches: ProfileMatch[] = [
	{
		match: '(https?://)?({PROFILE_ID})(.)*',
		group: 2,
		type: TYPE_DESKTOP,
		pattern: 'https://{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const LinkedInBusinessProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?linkedin.com/company/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://linkedin.com/company/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const SnapChatProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?snapchat.com/add/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://www.snapchat.com/add/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const PinterestProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?pinterest.com/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://www.pinterest.com/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const VimeoProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?vimeo.com/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://vimeo.com/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const SkypeProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?join.skype.com/invite/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://join.skype.com/invite/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];
const ImdbProfileMatches: ProfileMatch[] = [
	{
		match: '(https?://)?(www.)?imdb.com/user/({PROFILE_ID})/?',
		group: 3,
		type: TYPE_DESKTOP,
		pattern: 'https://www.imdb.com/user/{PROFILE_ID}',
	},
	{ match: '@?({PROFILE_ID})', group: 1 },
];

@Pipe({
	name: 'socialLink',
})
export class SocialLinkPipe implements PipeTransform {
	socialLink: SocialLinks = new SocialLinks({
		usePredefinedProfiles: true,
		allowQueryParams: true,
		trimInput: true,
	});

	constructor() {
		this.socialLink.addProfile('website', WebsiteMatches);
		this.socialLink.addProfile('facebook_business', facebook.matches);
		this.socialLink.addProfile('instagram_business', instagram.matches);
		this.socialLink.addProfile('linkedin_business', LinkedInBusinessProfileMatches);
		this.socialLink.addProfile('snapchat', SnapChatProfileMatches);
		this.socialLink.addProfile('skype', SkypeProfileMatches);
		this.socialLink.addProfile('pinterest', PinterestProfileMatches);
		this.socialLink.addProfile('vimeo', VimeoProfileMatches);
		this.socialLink.addProfile('imdb', ImdbProfileMatches);
	}

	isValid(type: string, value: string): boolean {
		if (!value) {
			console.log('isValid - no value present');
			return true;
		}

		if (!type || !this.socialLink.hasProfile(type)) {
			console.log('isValid - no profile');
			return false;
		}

		console.log('isValid - type', type);
		return this.socialLink.isValid(type, value);
	}

	transform(value: any, type: string, format: 'link' | 'id' = 'id'): string | undefined {
		try {
			const codedValue = this.encodeString(value);
			const isValid = this.isValid(type, codedValue);
			console.log('isValid', isValid);
			if (isValid) {
				return format === 'link'
					? this.decodeString(this.socialLink.sanitize(type, codedValue))
					: this.decodeString(this.socialLink.getProfileId(type, codedValue));
			} else {
				return format === 'link' ? undefined : this.decodeString(codedValue);
			}
		} catch (e) {
			console.error('transform error', e);
			return value;
		}
	}

	encodeString(value: string) {
		let codedValue = '';
		value.split('').forEach((c) => {
			codedValue = codedValue.concat(c === '/' || c === ':' ? c : encodeURIComponent(c));
		});
		codedValue = codedValue.replace(new RegExp('%', 'g'), 'bfrbvbfnbfldbvej');
		return codedValue;
	}

	decodeString(value: string) {
		return decodeURIComponent(value.replace(new RegExp('bfrbvbfnbfldbvej', 'g'), '%'));
	}
}
