/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Organization {
	id?: string;
	url?: string;
	name: string;
	mainAddress: string;
	taxNumber: string;
	registrationNumber: string;
	groupTaxNumber?: string;
	contactName?: string;
	contactMobile?: string;
	contactEmail?: string;
	constraints?: Array<string>;
	logoURL?: string;
	mnbNumber?: string;
}
