export * from './auth.service';
import { AuthService } from './auth.service';
export * from './campaigns.service';
import { CampaignsService } from './campaigns.service';
export * from './cards.service';
import { CardsService } from './cards.service';
export * from './chats.service';
import { ChatsService } from './chats.service';
export * from './contacts.service';
import { ContactsService } from './contacts.service';
export * from './courses.service';
import { CoursesService } from './courses.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './discounts.service';
import { DiscountsService } from './discounts.service';
export * from './exports.service';
import { ExportsService } from './exports.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './firebase.service';
import { FirebaseService } from './firebase.service';
export * from './languages.service';
import { LanguagesService } from './languages.service';
export * from './leagues.service';
import { LeaguesService } from './leagues.service';
export * from './links.service';
import { LinksService } from './links.service';
export * from './mail.service';
import { MailService } from './mail.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './orders.service';
import { OrdersService } from './orders.service';
export * from './organizations.service';
import { OrganizationsService } from './organizations.service';
export * from './referral.service';
import { ReferralService } from './referral.service';
export * from './roles.service';
import { RolesService } from './roles.service';
export * from './social.service';
import { SocialService } from './social.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './subscriptions.service';
import { SubscriptionsService } from './subscriptions.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export * from './themes.service';
import { ThemesService } from './themes.service';
export * from './to-do-list.service';
import { ToDoListService } from './to-do-list.service';
export * from './user-history.service';
import { UserHistoryService } from './user-history.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [
	AuthService,
	CampaignsService,
	CardsService,
	ChatsService,
	ContactsService,
	CoursesService,
	DefaultService,
	DiscountsService,
	ExportsService,
	FilesService,
	FirebaseService,
	LanguagesService,
	LeaguesService,
	LinksService,
	MailService,
	NotificationsService,
	OrdersService,
	OrganizationsService,
	ReferralService,
	RolesService,
	SocialService,
	StatisticsService,
	SubscriptionsService,
	TagsService,
	ThemesService,
	ToDoListService,
	UserHistoryService,
	UsersService,
];
