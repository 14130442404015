<h1 class="referrals-title">{{ 'core.referrals.title' | translate }}</h1>
<h2 class="user-name" *ngIf="!isOwn">{{ userName }}</h2>
<div *ngIf="!isLoading" class="referrals-container">
	<div class="referral-list-container">
		<div class="title">{{ 'core.referrals.first' | translate }}</div>
		<div class="reward">3000 {{ 'core.referrals.currency' | translate }}</div>
		<div class="count">{{ firstReferrals.length }} {{ 'core.referrals.amount' | translate }}</div>
		<mat-selection-list [multiple]="false">
			<mat-list-option *ngIf="firstReferrals.length > 0" value="all" [selected]="firstReferralAllSelected"
				(selectedChange)="firstReferralSelectAll($event)">
				{{ 'core.referrals.all' | translate }}
			</mat-list-option>
			<mat-list-option *ngFor="let referral of firstReferrals"
				(selectedChange)="firstReferralSelectedChange($event, referral)">
				{{ referral.name }}
			</mat-list-option>
		</mat-selection-list>
	</div>
	<div class="referral-list-container">
		<div class="title">{{ 'core.referrals.second' | translate }}</div>
		<div class="reward">1500 {{ 'core.referrals.currency' | translate }}</div>
		<div class="count">{{ secondReferrals.length }} {{ 'core.referrals.amount' | translate }}</div>
		<mat-selection-list *ngIf="isAdmin" [multiple]="false">
			<mat-list-option *ngIf="secondReferrals.length > 0" value="all" [selected]="secondReferralAllSelected"
				(selectedChange)="secondReferralSelectAll($event)">
				{{ 'core.referrals.all' | translate }}
			</mat-list-option>
			<mat-list-option *ngFor="let referral of secondReferrals"
				(selectedChange)="secondReferralSelectedChange($event, referral)">
				{{ referral.name }}
			</mat-list-option>
		</mat-selection-list>
	</div>
	<div class="referral-list-container">
		<div class="title">{{ 'core.referrals.third' | translate }}</div>
		<div class="reward">750 {{ 'core.referrals.currency' | translate }}</div>
		<div class="count">{{ thirdReferrals.length }} {{ 'core.referrals.amount' | translate }}</div>
		<mat-selection-list *ngIf="isAdmin" [multiple]="false">
			<mat-list-option *ngIf="thirdReferrals.length > 0" value="all" [selected]="thirdReferralAllSelected"
				(selectedChange)="thirdReferralSelectAll($event)">
				{{ 'core.referrals.all' | translate }}
			</mat-list-option>
			<mat-list-option *ngFor="let referral of thirdReferrals"
				(selectedChange)="thirdReferralSelectedChange($event, referral)">
				{{ referral.name }}
			</mat-list-option>
		</mat-selection-list>
	</div>
	<div class="referral-list-container">
		<div class="title">{{ 'core.referrals.fourth' | translate }}</div>
		<div class="reward">500 {{ 'core.referrals.currency' | translate }}</div>
		<div class="count">{{ fourthReferrals.length }} {{ 'core.referrals.amount' | translate }}</div>
		<mat-selection-list *ngIf="isAdmin" [multiple]="false">
			<mat-list-option *ngIf="fourthReferrals.length > 0" value="all" [selected]="fourthReferralAllSelected"
				(selectedChange)="fourthReferralSelectAll($event)">
				{{ 'core.referrals.all' | translate }}
			</mat-list-option>
			<mat-list-option *ngFor="let referral of fourthReferrals"
				(selectedChange)="fourthReferralSelectedChange($event, referral)">
				{{ referral.name }}
			</mat-list-option>
		</mat-selection-list>
	</div>
	<div class="referral-list-container">
		<div class="title">{{ 'core.referrals.fifth' | translate }}</div>
		<div class="reward">250 {{ 'core.referrals.currency' | translate }}</div>
		<div class="count">{{ fifthReferrals.length }} {{ 'core.referrals.amount' | translate }}</div>
		<mat-selection-list *ngIf="isAdmin" [multiple]="false">
			<mat-list-option *ngFor="let referral of fifthReferrals">
				{{ referral.name }}
			</mat-list-option>
		</mat-selection-list>
	</div>
</div>
<h3 *ngIf="!isLoading">Összes referálás összege: {{ getReferralTotal() | number : '' }} Ft</h3>
<div *ngIf="isLoading" class="loader-container">
	<mat-spinner></mat-spinner>
</div>