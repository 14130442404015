<div *ngIf="!isMyProfile" class="w-100 d-flex justify-content-center mb-4">
  <button mat-flat-button color="accent" (click)="onClick()">
    {{ 'core.profile.download-contact' | translate }}
  </button>
</div>

<h1 *ngIf="isPublicView" class="text-center">Üdv, {{ profile.lastName }} {{ profile.firstName }} vagyok</h1>

<div *ngIf="theme" class="profile-container row justify-content-center p-0">
  <section class="header col p-0 px-md-3">
    <mat-card class="img-container mb-4" [ngStyle]="{ 'background-color': theme?.backgroundColor || 'transparent'}">
      <mat-card-header class="d-flex justify-content-center">
        <img *ngIf="profile?.profileURL" [src]="profile?.profileURL"/>
        <img *ngIf="!profile?.profileURL" src="assets/icon/icons-primary-dark/account_circle.svg"/>
      </mat-card-header>
      <mat-card-content class="d-flex flex-column align-items-center pt-4 pb-2">
        <h3 class="my-1">
          <span class="text-white">
            {{ profile?.lastName }} {{ profile?.firstName }}
          </span>
          <img *ngIf="isUserVerified()" class="mb-1" src="assets/icon/icons-primary-dark/verified.svg" [style.filter]="theme?.mainFilter"/>
          <img *ngIf="!isUserVerified() && !showTags && !isPublicView" class="mb-1" src="assets/icon/icons-primary-dark/not_verified.svg" [style.filter]="theme?.mainFilter"/>
        </h3>
        <div class="my-1 text-center text-white">
          <h4 class="my-2">
            {{ profile?.title }}
          </h4>
          <h4 class="my-2">
            {{ profile?.organization }}
          </h4>
        </div>
      </mat-card-content>

      <!-- TODO: implement TAGS -->
    </mat-card>

    <div *ngIf="organization || profile?.mnbNumber" class="company-validation my-4 d-flex flex-column justify-content-center">
      <img *ngIf="organization?.logoURL" [src]="organization.logoURL" class="mb-2 mx-auto"/>
      <p *ngIf="organization?.name" class="mb-1 text-center"><strong>{{ organization.name }} által hitelesített profil</strong></p>
      <p *ngIf="organization?.mnbNumber" class="mt-2 mb-2 text-center">Az igazolvány nem átruházható. Az érvényesség feltétele, hogy birtokosa az MNB Biztosításközvetítői regiszterben a(z) {{ organization.name }} neve alatt aktív státuszban szerepeljen.</p>
      <p *ngIf="organization?.mnbNumber" class="mb-0 text-center">Szervezet MNB azonosítószáma: {{ organization.mnbNumber }}</p>
      <p *ngIf="profile?.mnbNumber" class="mb-0 text-center">Személyes MNB azonosítószám: {{ profile.mnbNumber }}</p>
    </div>

    <mat-card *ngIf="isOrgDetailsPresent()" class="my-4">
      <mat-card-content [style]="{color: theme?.textColor}">
        <div *ngIf="profile?.organization" class="w-100 m-1">
          {{ profile?.organization }}
        </div>
        <div *ngIf="profile?.orgAddress" class="w-100 m-1">
          {{ profile?.orgAddress }}
        </div>
        <div *ngIf="profile?.orgTaxNumber" class="w-100 m-1">
          {{ profile?.orgTaxNumber }}
        </div>
        <div *ngIf="profile?.orgRegistrationNumber" class="w-100 m-1">
          {{ profile?.orgRegistrationNumber }}
        </div>
        <div *ngIf="profile?.mnbNumber" class="w-100 m-1">
          {{ profile.mnbNumber }}
        </div>
      </mat-card-content>
    </mat-card>
  </section>

  <section *ngIf="isLanguagesPresent() || (profile && (profile.videoURL || profile.fileURL))" class="col p-0 px-md-3">
    <mat-card *ngIf="profile && profile?.videoURL" class="video-section d-flex flex-row justify-content-between align-items-center p-0 mb-4" [style.color]="theme?.textColor">
      <p class="mb-0 mx-3" [style.color]="theme?.textColor">
        Nézd meg a videómat
      </p>
      <iframe *ngIf="profile?.videoURL" [id]="'profileVideo_' + uid" class="video-js vjs-default-skin" width="184" height="96"></iframe>
    </mat-card>

    <mat-card *ngIf="profile && profile?.fileURL && profile?.fileType" [style.color]="theme?.textColor" class="data flex flex-col p-0 mb-4">
      <a [href]="profile.fileURL" class="d-flex justify-content-between align-items-center z-10 p-3" target="_blank">
      <span [style.color]="theme?.textColor">
        {{ 'users.new.form.file-type-' + profile.fileType | translate }}
      </span>
        <img src="assets/icon/icons-primary-dark/download.svg" [style.filter]="theme?.mainFilter"/>
      </a>
    </mat-card>

    <mat-card *ngIf="isLanguagesPresent()" class="mb-4">
      <mat-card-content [style]="{color: theme?.textColor}">
        <div class="d-flex flex-row justify-content-between my-2" *ngFor="let item of getLangItems()">
          <p class="m-0">{{ item.key }}</p>
          <p class="m-0">{{ item.value }}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </section>

  <section *ngIf="isContactDetailsPresent() || isSocialLinkPresent()" class="social col p-0 px-md-3">
    <mat-card *ngIf="isContactDetailsPresent()" [style.color]="theme?.textColor" class="mb-4">
      <div class="my-2 flex" *ngIf="profile?.chatBot">
        <img class="icon" src="assets/icon/icons-primary-dark/forum.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="profile?.chatBot" [style.color]="theme?.textColor">
          ChatBot link
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.questionnaire">
        <img class="icon" src="assets/icon/icons-primary-dark/ballot.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="profile?.questionnaire" [style.color]="theme?.textColor">
          Questionnaire link
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.homeAddress">
        <img class="icon" src="assets/icon/icons-primary-dark/location_on.svg" [style.filter]="theme?.mainFilter"/>
        <span>{{ profile?.homeAddress }}</span>
      </div>

      <div class="my-2 flex" *ngIf="profile?.cellPhone">
        <img class="icon" src="assets/icon/icons-primary-dark/call.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'tel:' + profile?.cellPhone" [style.color]="theme?.textColor" class="flex items-center mr-2">
          <span>{{ profile?.cellPhone }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.cellPhone2">
        <img class="icon" src="assets/icon/icons-primary-dark/call.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'tel:' + profile?.cellPhone2" [style.color]="theme?.textColor" class="flex items-center mr-2">
          <span>{{ profile?.cellPhone2 }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.cellPhone3">
        <img class="icon" src="assets/icon/icons-primary-dark/call.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'tel:' + profile?.cellPhone3" [style.color]="theme?.textColor" class="flex items-center mr-2">
          <span>{{ profile?.cellPhone3 }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.cellPhone4">
        <img class="icon" src="assets/icon/icons-primary-dark/call.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'tel:' + profile?.cellPhone4" [style.color]="theme?.textColor" class="flex items-center mr-2">
          <span>{{ profile?.cellPhone4 }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.whatsapp">
        <img class="icon" src="assets/icon/icons-social/whatsapp.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'tel:' + profile?.whatsapp" [style.color]="theme?.textColor">
          <span>{{ profile?.whatsapp }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.email">
        <img class="icon" src="assets/icon/icons-primary-dark/mail.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'mailto:' + profile?.email" [style.color]="theme?.textColor">
          <span>{{ profile?.email }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.email2">
        <img class="icon" src="assets/icon/icons-primary-dark/mail.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'mailto:' + profile?.email2" [style.color]="theme?.textColor">
          <span>{{ profile?.email2 }}</span>
        </a>
      </div>

      <div class="my-2 flex" *ngIf="profile?.email3">
        <img class="icon" src="assets/icon/icons-primary-dark/mail.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="'mailto:' + profile?.email3" [style.color]="theme?.textColor">
          <span>{{ profile?.email3 }}</span>
        </a>
      </div>
      <div class="my-2 flex" *ngIf="profile?.birthday">
        <img class="icon" src="assets/icon/icons-primary-dark/cake.svg" [style.filter]="theme?.mainFilter"/>
        <span>{{ parseBirthDate(profile?.birthday) }}</span>
      </div>

      <div class="my-2 flex" *ngIf="profile?.titleNew">
        <img class="icon" src="assets/icon/icons-primary-dark/work.svg" [style.filter]="theme?.mainFilter"/>
        <span>{{ profile?.titleNew?.name?.[ts.currentLang] || '-' }}</span>
      </div>

      <div class="my-2 flex" *ngIf="profile?.url">
        <img class="icon" src="assets/icon/icons-primary-dark/language.svg" [style.filter]="theme?.mainFilter"/>
        <a [href]="getUrl(profile?.url)" [style.color]="theme?.textColor" target="_blank">
					<span>{{ getShortUrl(profile?.url) }}</span>
        </a>
      </div>

      <div *ngIf="profile?.notes" class="my-2 flex">
        <img class="icon" src="assets/icon/icons-primary-dark/sell.svg" [style.filter]="theme?.mainFilter"/>
        <span>{{ profile?.notes }}</span>
      </div>
    </mat-card>

    <mat-card *ngIf="isSocialLinkPresent()" class="my-4">
      <mat-card-content [style]="{color: theme?.textColor}">
        <ng-container *ngFor="let socialElement of socialElements">
          <div *ngIf="profile[socialElement.field]" class="my-2 flex">
            <img class="icon" [src]="socialElement.img" [style.filter]="theme?.mainFilter"/>
            <a target="_blank" [style.color]="theme?.textColor" [href]="socialLinkPipe.transform(profile[socialElement.field],socialElement.type,'link')">
              <span *ngIf="!socialElement.showLabel">
                {{ profile[socialElement.field] | socialLink : socialElement.type : 'id' }}
              </span>
              <span *ngIf="socialElement.showLabel">
                {{ socialElement.showLabel(profile) }}
              </span>
            </a>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </section>
</div>
