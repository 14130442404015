<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>{{ data.message }}</div>
<div mat-dialog-actions class="row justify-content-between mb-2">
  <div class="col">
    <button mat-flat-button color="warn" (click)="dialogRef.close(true)">
      Törlés
    </button>
  </div>
  <div class="col">
    <button mat-stroked-button (click)="dialogRef.close(false)">
      Mégsem
    </button>
  </div>
</div>
