import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './modules/shared/components/error/error.component';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./modules/core/core.module').then((m) => m.CoreModule) },
	{ path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) },
	{ path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule) },
	{
		path: 'companies',
		loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
	},
	{ path: 'orders', loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule) },
	{ path: 'payouts', loadChildren: () => import('./modules/payouts/payouts.module').then((m) => m.PayoutsModule) },
	{ path: 'users', loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule) },
	{ path: 'system', loadChildren: () => import('./modules/system/system.module').then((m) => m.SystemModule) },
	{ path: 'help', loadChildren: () => import('./modules/core/core.module').then((m) => m.CoreModule) },

	{ path: 'error', component: ErrorComponent },
	{ path: '**', redirectTo: '/error', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
