import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-crop-img',
	templateUrl: './crop-img.component.html',
	styleUrls: ['./crop-img.component.scss'],
})
export class CropImgComponent {
	croppedImage: any = '';

	constructor(
		public dialogRef: MatDialogRef<CropImgComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	imageCropped(event: ImageCroppedEvent) {
		const reader = new FileReader();

		reader.addEventListener('load', async (e: any) => {
			this.croppedImage = e.target.result;
		});

		reader.readAsDataURL(event.blob);
	}

	async closeModal() {
		console.log('closeModal() called');
		await this.dialogRef.close();
	}

	async cropImg() {
		console.log('cropImg() called');
		await this.dialogRef.close(this.croppedImage);
	}
}
