/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignMessage } from './campaign-message';

export interface Campaign {
	id?: string;
	enabled: boolean;
	name: string;
	triggerEvent: Campaign.TriggerEventEnum;
	messages: Array<CampaignMessage>;
}
export namespace Campaign {
	export type TriggerEventEnum = 'NEW_CONTACT' | 'NEW_MEETING' | 'MANUAL';
	export const TriggerEventEnum = {
		NewContact: 'NEW_CONTACT' as TriggerEventEnum,
		NewMeeting: 'NEW_MEETING' as TriggerEventEnum,
		Manual: 'MANUAL' as TriggerEventEnum,
	};
}
