/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LeagueParticipant {
	id: string;
	rank?: number;
	league?: LeagueParticipant.LeagueEnum;
	monogram?: string;
	sum: number;
	interval?: string;
	isCurrentUser?: boolean;
	isActive?: boolean;
}
export namespace LeagueParticipant {
	export type LeagueEnum = 'bronze' | 'silver' | 'gold' | 'amber';
	export const LeagueEnum = {
		Bronze: 'bronze' as LeagueEnum,
		Silver: 'silver' as LeagueEnum,
		Gold: 'gold' as LeagueEnum,
		Amber: 'amber' as LeagueEnum,
	};
}
