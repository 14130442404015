import { LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { AuthsService } from './services/auths.service';
import { firstValueFrom } from 'rxjs';
import { ApiModule, BASE_PATH } from './modules/api';
import { BearerInterceptor } from './providers/bearer.interceptor';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		SharedModule,
		FormsModule,
		HttpClientModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule,
		TranslateModule.forRoot({
			defaultLanguage: 'hu',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireFunctionsModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireDatabaseModule,
		ReactiveFormsModule,
		FormsModule,
		ApiModule,
		MatDialogModule,
		MatButtonModule,
		MatSidenavModule,
	],
	providers: [
		AuthsService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true,
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
		{ provide: REGION, useValue: 'europe-west3' },
		{ provide: BASE_PATH, useValue: environment.backendURL },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BearerInterceptor,
			multi: true,
		},
		{
			provide: MatDialogRef,
			useValue: {},
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}

// eslint-disable-next-line
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// eslint-disable-next-line
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return () =>
		new Promise<any>(async (resolve: any) => {
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			await locationInitialized.then(async () => {
				const langToSet = 'hu';
				translate.setDefaultLang(langToSet);
				await firstValueFrom(translate.use(langToSet))
					.then(() => {
						// eslint-disable-next-line
						console.info(`Successfully initialized '${langToSet}' language.'`);
					})
					.catch(() => {
						// eslint-disable-next-line
						console.error(`Problem with '${langToSet}' language initialization.'`);
					})
					.finally(() => {
						resolve(null);
					});
			});
		});
}
