import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthsService } from '../../../services/auths.service';
import { Link, ReferralService, UsersService } from '../../api';
import { firstValueFrom } from 'rxjs';
import ProfileEnum = Link.ProfileEnum;
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ReferredUser {
	id: string;
	name: string;
	level: number;
	referredBy: string;
}

@Component({
	selector: 'app-referrals',
	templateUrl: './referrals.component.html',
	styleUrls: ['./referrals.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralsComponent implements OnInit {
	users: ReferredUser[] = [];

	firstReferrals: ReferredUser[] = [];
	firstReferralAllSelected = true;
	secondReferrals: ReferredUser[] = [];
	secondReferralAllSelected = true;
	thirdReferrals: ReferredUser[] = [];
	thirdReferralAllSelected = true;
	fourthReferrals: ReferredUser[] = [];
	fourthReferralAllSelected = true;
	fifthReferrals: ReferredUser[] = [];

	isOwn = true;
	isAdmin = false;
	isLoading = false;

	userName: string = null;

	constructor(
		private authService: AuthsService,
		private referralService: ReferralService,
		private translate: TranslateService,
		private cdRef: ChangeDetectorRef,
		private userService: UsersService,
		@Inject(MAT_DIALOG_DATA) public id: string
	) {
		this.isAdmin = this.authService.hasGeneralAdminAuthority();
	}

	async ngOnInit() {
		if (this.id != null) {
			this.getReferralCards(this.id);
			const currentUserId = this.authService.currentUser?.uid;
			if (currentUserId !== null && this.id !== currentUserId) {
				this.isOwn = false;
				const profile = await firstValueFrom(
					this.userService.usersAdminControllerGetUserProfile(this.id, ProfileEnum.Primary)
				);
				this.userName = `${profile.lastName || ''} ${profile.firstName || ''}`;
				this.cdRef.detectChanges();
			}
		}
	}

	getReferralCards(id: string) {
		this.isLoading = true;
		this.cdRef.detectChanges();
		firstValueFrom(this.referralService.referralControllerGetUserBonusList(id))
			.then(async (result) => {
				for (const card of result) {
					if (card.userID != null) {
						const profile = await firstValueFrom(
							this.userService.usersAdminControllerGetUserProfile(this.id, ProfileEnum.Primary)
						);
						if (profile != null) {
							const name =
								profile.lastName != null && profile.firstName != null
									? `${profile.lastName} ${profile.firstName}`
									: this.translate.instant('core.referrals.deleted-user');
							this.users.push({
								id: card.userID,
								name,
								level: card.level,
								referredBy: card?.referredBy,
							});
						}
					}
				}
				this.firstReferrals = this.users.filter((x) => x.level === 0);
				this.secondReferrals = this.users.filter((x) => x.level === 1);
				this.thirdReferrals = this.users.filter((x) => x.level === 2);
				this.fourthReferrals = this.users.filter((x) => x.level === 3);
				this.fifthReferrals = this.users.filter((x) => x.level === 4);
				this.isLoading = false;
				this.cdRef.detectChanges();
			})
			.catch(() => (this.isLoading = false));
	}

	firstReferralSelectAll(value: boolean) {
		this.firstReferralAllSelected = value;
		if (value) {
			this.secondReferrals = this.users.filter((x) => x.level === 1);
			this.cdRef.detectChanges();
			this.secondReferralSelectAll(true);
		}
	}

	firstReferralSelectedChange(value: boolean, referral: ReferredUser) {
		if (value) {
			this.secondReferrals = this.users.filter((x) => x.level === 1 && x.referredBy === referral.id);
			if (this.secondReferrals.length === 0) {
				this.thirdReferrals = [];
				this.fourthReferrals = [];
				this.fifthReferrals = [];
				this.cdRef.detectChanges();
			} else {
				this.secondReferralSelectAll(true);
			}
		}
	}

	secondReferralSelectAll(value: boolean) {
		this.secondReferralAllSelected = value;
		if (value) {
			if (this.secondReferrals.length > 0) {
				this.thirdReferrals = this.users.filter(
					(x) => x.level === 2 && this.secondReferrals.some((r) => r.id === x.referredBy)
				);
			} else {
				this.thirdReferrals = [];
			}
			this.cdRef.detectChanges();
			this.thirdReferralSelectAll(true);
		}
	}

	secondReferralSelectedChange(value: boolean, referral: ReferredUser) {
		if (value) {
			this.thirdReferrals = this.users.filter((x) => x.level === 2 && x.referredBy === referral.id);
			if (this.thirdReferrals.length === 0) {
				this.fourthReferrals = [];
				this.fifthReferrals = [];
				this.cdRef.detectChanges();
			} else {
				this.thirdReferralSelectAll(true);
			}
		}
	}

	thirdReferralSelectAll(value: boolean) {
		this.thirdReferralAllSelected = value;
		if (value) {
			if (this.thirdReferrals.length > 0) {
				this.fourthReferrals = this.users.filter(
					(x) => x.level === 3 && this.thirdReferrals.some((r) => r.id === x.referredBy)
				);
			} else {
				this.fourthReferrals = [];
			}
			this.cdRef.detectChanges();
			this.fourthReferralSelectAll(true);
		}
	}

	thirdReferralSelectedChange(value: boolean, referral: ReferredUser) {
		if (value) {
			this.fourthReferrals = this.users.filter((x) => x.level === 3 && x.referredBy === referral.id);
			if (this.fourthReferrals.length === 0) {
				this.fifthReferrals = [];
				this.cdRef.detectChanges();
			} else {
				this.fourthReferralSelectAll(true);
			}
		}
	}

	fourthReferralSelectAll(value: boolean) {
		this.fourthReferralAllSelected = value;
		if (value) {
			if (this.fourthReferrals.length > 0) {
				this.fifthReferrals = this.users.filter(
					(x) => x.level === 4 && this.fourthReferrals.some((r) => r.id === x.referredBy)
				);
			} else {
				this.fifthReferrals = [];
			}
			this.cdRef.detectChanges();
		}
	}

	getReferralTotal() {
		return (
			3000 * this.firstReferrals.length +
			1500 * this.secondReferrals.length +
			750 * this.thirdReferrals.length +
			500 * this.fourthReferrals.length +
			250 * this.fifthReferrals.length
		);
	}

	fourthReferralSelectedChange(value: any, referral: ReferredUser) {
		if (value) {
			this.fifthReferrals = this.users.filter((x) => x.level === 4 && x.referredBy === referral.id);
			this.cdRef.detectChanges();
		}
	}
}
