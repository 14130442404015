/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Title } from './title';

export interface Language {
	ar?: Title;
	de?: Title;
	en?: Title;
	es?: Title;
	fr?: Title;
	hu?: Title;
	it?: Title;
	ru?: Title;
	zh?: Title;
}
