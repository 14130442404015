<div class="left-column">
	<h1>
		{{ 'shared.error.title' | translate }}
	</h1>
	<p [innerHTML]="'shared.error.text' | translate"></p>
</div>
<div class="right-column">
	<a [routerLink]="''">
		<img src="assets/cim-logo-white.svg" />
	</a>
</div>
