<ng-template>
	<h2 class="text-center mb-4 text-xl font-medium px-5">{{ 'settings.tag-settings' | translate }}</h2>
	<app-loading-spinner *ngIf="!tags"></app-loading-spinner>
	<ul class="flex flex-col mx-8" *ngIf="tags">
		<li class="flex items-center" *ngFor="let tag of tags">
			<div class="tag-circle" [ngStyle]="{ 'background-color': tag.background }"
				*ngIf="tag.name !== null || isEditMode"></div>
			<h2 class="text-gold my-3" *ngIf="!isEditMode && tag.name !== null && tag.name !== undefined">
				{{ tag.name || ('shared.undefined' | translate) || '-' }}
			</h2>
			<input type="text" [(ngModel)]="tag.name" class="cim-form-input"
				placeholder="{{ 'settings.tag-name' | translate }}" *ngIf="isEditMode" />
		</li>
		<li class="mx-auto mt-4 buttons justify-center flex flex-col">
			<button *ngIf="!isEditMode" (click)="edit()" class="whitespace-normal">
				{{ 'settings.edit-tags' | translate }}
			</button>
			<div mat-button class="flex" *ngIf="isEditMode">
				<button mat-button (click)="save()">{{ 'settings.save' | translate }}</button>
				<button mat-button (click)="cancel()" fill="outline">{{ 'settings.cancel' | translate }}</button>
			</div>
			<button mat-button *ngIf="showCloseButton" (click)="dismiss()" color="light">
				{{ 'settings.go-back' | translate }}
			</button>
		</li>
	</ul>
</ng-template>
