import { Component, Input, OnInit } from '@angular/core';
import { TagSettingsComponent } from '../../../core/tag-settings/tag-settings.component';
import { Tag, TagsService } from '../../../api';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
	selector: 'app-tag-setter',
	templateUrl: './tag-setter.component.html',
	styleUrls: ['./tag-setter.component.scss'],
})
export class TagSetterComponent implements OnInit {
	@Input() tagNumbers: number[] = [];

	tags: Tag[] = [];
	checkedTags: any = {};
	numOfChecked?: number;

	constructor(
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<TagSetterComponent>,
		private tagService: TagsService
	) {}

	async ngOnInit() {
		this.tags = await firstValueFrom(this.tagService.tagsControllerGetMyUserTags());

		Array.from(Array(12).keys()).forEach((x) => {
			this.checkedTags[x] = this.tagNumbers.includes(x);
		});
		this.numOfChecked = Object.values(this.checkedTags).filter((x) => x === true).length;
		console.log('tag init done');
	}

	dismiss() {
		this.dialogRef.close();
	}

	save() {
		const tagNumbers = Object.keys(this.checkedTags)
			.filter((x) => this.checkedTags[x] === true)
			.map((x) => parseInt(x, 10));
		console.log('new tagNumbers');
		this.dialogRef.close(tagNumbers);
	}

	onChange(ev: MatCheckboxChange) {
		this.numOfChecked = Object.values(this.checkedTags).filter((x) => x === true).length;

		if (this.numOfChecked > 4) {
			// @ts-expect-error ignore unknown field
			ev.target.checked = false;
		}
	}

	async openSettings() {
		this.dialog.open(TagSettingsComponent, {});
	}
}
