<div class="d-flex justify-content-between align-items-center">
  <h2 class="ms-2 mb-0">
    QR kód
  </h2>
  <button mat-icon-button color="primary" (click)="onDownloadClick()">
    <img src="assets/icon/icons-secondary-dark/download.svg" />
  </button>
</div>
<div *ngIf="content">
  <qrcode
    id="userQrCode"
    elementType="img"
    (qrCodeURL)="onChangeURL($event)"
    [allowEmptyString]="true"
    [qrdata]="content"
    class="max-w-full"
    cssClass="flex justify-center m-4 p-2 bg-white block"
    [margin]="0"
    [width]="300"
    colorDark="#000"
    colorLight="#fff"
    [errorCorrectionLevel]="'M'"
  ></qrcode>
</div>
