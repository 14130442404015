/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Event {
	id?: string;
	deviceInfo?: object;
	appInfo?: object;
	userInfo?: object;
	eventType: Event.EventTypeEnum;
	extraData: object;
	createdAt?: string;
	isSystemEvent?: boolean;
}
export namespace Event {
	export type EventTypeEnum =
		| 'APP_INIT_WITH_USER'
		| 'CARD_REGISTRATION'
		| 'MESSAGE_SENT'
		| 'MESSAGE_RECEIVED'
		| 'ERROR_LOGGING'
		| 'FIX_REMINDER_RECEIVED'
		| 'CONDITIONAL_REMINDER_RECEIVED';
	export const EventTypeEnum = {
		AppInitWithUser: 'APP_INIT_WITH_USER' as EventTypeEnum,
		CardRegistration: 'CARD_REGISTRATION' as EventTypeEnum,
		MessageSent: 'MESSAGE_SENT' as EventTypeEnum,
		MessageReceived: 'MESSAGE_RECEIVED' as EventTypeEnum,
		ErrorLogging: 'ERROR_LOGGING' as EventTypeEnum,
		FixReminderReceived: 'FIX_REMINDER_RECEIVED' as EventTypeEnum,
		ConditionalReminderReceived: 'CONDITIONAL_REMINDER_RECEIVED' as EventTypeEnum,
	};
}
