/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Todo {
	id?: string;
	name: string;
	userId?: string;
	targetUserIds?: Array<string>;
	details?: string;
	category?: Todo.CategoryEnum;
	createdAt?: string;
	scheduledAt: string;
	doneAt?: string;
}
export namespace Todo {
	export type CategoryEnum = 'CALL' | 'EMAIL' | 'OFFER';
	export const CategoryEnum = {
		Call: 'CALL' as CategoryEnum,
		Email: 'EMAIL' as CategoryEnum,
		Offer: 'OFFER' as CategoryEnum,
	};
}
