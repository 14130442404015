import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthsService } from '../../../../services/auths.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	constructor(
		private authService: AuthsService,
		private router: Router,
		private snackBar: MatSnackBar
	) {}

	isAuthenticated(): boolean {
		return this.authService.isAuthenticated();
	}

	logout(): void {
		this.authService
			.logout()
			.then(() => this.router.navigate(['/']))
			.catch((e) => this.snackBar.open(e.message, 'X', { panelClass: 'error' }));
	}

	goToShopping() {
		window.open('https://cardinmove.com/termekek/', '_blank');
	}
}
