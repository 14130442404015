import { Component, Input, OnInit } from '@angular/core';
import { Tag, TagsService } from '../../api';
import { firstValueFrom } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-tag-settings',
	templateUrl: './tag-settings.component.html',
	styleUrls: ['./tag-settings.component.scss'],
})
export class TagSettingsComponent implements OnInit {
	@Input() showCloseButton = false;

	tags: Tag[] = undefined;
	savedTags?: Tag[];
	isEditMode = false;

	constructor(
		public tagService: TagsService,
		private dialogRef: MatDialogRef<TagSettingsComponent>
	) {}

	ngOnInit() {
		firstValueFrom(this.tagService.tagsControllerGetMyUserTags()).then((tags) => {
			this.tags = tags ? tags : [];
		});
	}

	edit() {
		this.savedTags = JSON.parse(JSON.stringify(this.tags));
		this.isEditMode = true;
	}

	async save() {
		await firstValueFrom(this.tagService.tagsControllerUpdateMyUserTags({ data: this.tags }));
		this.isEditMode = false;
	}

	cancel() {
		this.tags = JSON.parse(JSON.stringify(this.savedTags));
		this.isEditMode = false;
	}

	dismiss() {
		this.dialogRef.close();
	}
}
