<main class="m-3">
  <header class="d-flex justify-content-between align-items-center">
    <h2 class="m-0">Kép méretre vágása</h2>
    <button mat-icon-button color="primary" (click)="closeModal()">
      <img src="assets/icon/icons-secondary-dark/close.svg"/>
    </button>
  </header>

  <div class="cropper-container mt-2">
    <image-cropper
      [maintainAspectRatio]="true"
      format="png"
      [imageBase64]="data.url"
      [aspectRatio]="1/1"
      [roundCropper]="true"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
    <button class="crop-button" mat-raised-button color="primary" (click)="cropImg()">
      <span>Vágás</span>
    </button>
  </div>
</main>
