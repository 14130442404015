<ng-template>
	<mat-toolbar>
		<img slot="start" class="w-8 mx-4" src="../../../../../assets/cim-logo-icon-white.svg" />
		<p>CardInMove</p>
		<button mat-button (click)="dismiss()" slot="end" fill="outline" class="without-border">
			<mat-icon slot="icon-only" name="close-outline"></mat-icon>
		</button>
	</mat-toolbar>
</ng-template>
<ng-container>
	<h2 class="text-center mb-4 text-xl font-medium px-5">{{ 'shared.set-tags' | translate }}</h2>
	<span class="text-center text-gray-400 small block">
		{{ 'shared.num-of-tags-used' | translate }} {{ numOfChecked }} / 4
	</span>
	<ul class="w-2/3 mx-auto">
		<li *ngFor="let tag of tags" lines="none">
			<mat-checkbox slot="end" [checked]="checkedTags[tag.id]" (change)="onChange($event)"
				[style]="'--background-checked: ' + tag.background + '; --border-color-checked: ' + tag.background">
			</mat-checkbox>
			<mat-label>{{ tag.name }}</mat-label>
		</li>
	</ul>
	<div class="w-2/3 mx-auto mt-4 buttons flex justify-center">
		<button mat-button class="px-4" (click)="save()">{{ 'shared.date-picker-save' | translate }}</button>
		<button mat-button class="px-4" (click)="dismiss()" fill="outline">
			{{ 'shared.nfc-cancel' | translate }}
		</button>
	</div>
	<div class="w-full mx-auto mt-4 buttons flex justify-center">
		<button mat-button class="px-4" (click)="openSettings()" color="light">
			{{ 'shared.tag-settings' | translate }}
		</button>
	</div>
</ng-container>
