/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingTaxCountry } from './billing-tax-country';

export interface BillingSettings {
	billingName?: string;
	billingAddress?: string;
	billingPersonType?: string;
	billingTaxNumber?: string;
	billingEmail?: string;
	billingBankAccountNumber?: string;
	billingAddressCheckboxAccepted?: boolean;
	billingTaxCountry?: BillingTaxCountry;
	billingIsEmployee?: boolean;
	billingPhone?: string;
	billingTaxCompanyType?: string;
	billingTaxCheckboxAccepted?: boolean;
}
