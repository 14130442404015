/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ListUsersResDto {
	id: string;
	login: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	cellPhone?: string;
	avatarURL?: string;
	unpaidBonus?: number;
}
