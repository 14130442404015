<mat-sidenav-container class="container-fluid h-100 p-0">
  <mat-sidenav [mode]="isMobileView ? 'over' : 'side'" [disableClose]="!isMobileView" [(opened)]="isMenuOpened" (click)="closeMenu()">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="bg-white">
    <div *ngIf="isMobileView" class="navbar d-flex justify-content-between">
      <div class="logo-container ms-3">
        <a [routerLink]="['']" class="logo">
          <img class="nav-logo" src="assets/cim-logo-icon-black.svg" />
          <p>Card In Move</p>
        </a>
      </div>
      <button mat-icon-button color="accent" (click)="openMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="content p-4" [class.mobile-view]="isMobileView">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="!isProd" class="test-flag">TEST</div>
