/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignReminder } from './campaign-reminder';
import { CampaignMessageTiming } from './campaign-message-timing';

export interface CampaignMessage {
	subject: string;
	timing: CampaignMessageTiming;
	channel: CampaignMessage.ChannelEnum;
	reminder?: CampaignReminder;
	startDate: number;
	content: string;
}
export namespace CampaignMessage {
	export type ChannelEnum = 'EMAIL' | 'MESSAGE';
	export const ChannelEnum = {
		Email: 'EMAIL' as ChannelEnum,
		Message: 'MESSAGE' as ChannelEnum,
	};
}
