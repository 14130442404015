import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthsService } from '../../../services/auths.service';

@Directive({
	// eslint-disable-next-line
	selector: '[hasAnyResourceAuthority]',
})
export class HasAnyResourceAuthorityDirective implements OnDestroy {
	private resource?: string;
	private authorities!: string | string[];

	private readonly destroy$ = new Subject<void>();

	constructor(
		private authService: AuthsService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef
	) {}

	@Input()
	set hasAnyResourceAuthority(params: { resource?: string; authorities: string | string[] }) {
		this.resource = params.resource;
		this.authorities = params.authorities;
		this.updateView();

		this.authService.authoritiesRefreshed.subscribe(() => {
			this.updateView();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateView(): void {
		if (!this.resource) {
			return;
		}

		const hasAnyAuthority = this.authService.hasAnyResourceAuthority(this.resource, this.authorities);

		this.viewContainerRef.clear();
		if (hasAnyAuthority) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}
}
