/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ReferralBonus {
	id: string;
	cardID?: string;
	level?: number;
	userID?: string;
	referredBy: string;
	when?: string;
	paid?: boolean;
	remainingAmount?: number;
	payoutID?: string;
}
