/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpResponse,
	HttpEvent,
	HttpParameterCodec,
	HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { NfcCard } from '../model/nfc-card';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
	providedIn: 'root',
})
export class CardsService {
	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string | string[],
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (Array.isArray(basePath) && basePath.length > 0) {
				basePath = basePath[0];
			}

			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	// @ts-ignore
	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object' && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
				} else {
					throw Error('key may not be null if value is Date');
				}
			} else {
				Object.keys(value).forEach(
					(k) =>
						(httpParams = this.addToHttpParamsRecursive(
							httpParams,
							value[k],
							key != null ? `${key}.${k}` : k
						))
				);
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error('key may not be null if value is not object or array');
		}
		return httpParams;
	}

	/**
	 * @param nfcCard
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public cardsControllerCreateCard(
		nfcCard: NfcCard,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<NfcCard>;
	public cardsControllerCreateCard(
		nfcCard: NfcCard,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<NfcCard>>;
	public cardsControllerCreateCard(
		nfcCard: NfcCard,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<NfcCard>>;
	public cardsControllerCreateCard(
		nfcCard: NfcCard,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (nfcCard === null || nfcCard === undefined) {
			throw new Error('Required parameter nfcCard was null or undefined when calling cardsControllerCreateCard.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/cards`;
		return this.httpClient.request<NfcCard>('put', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: nfcCard,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param cardId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public cardsControllerDeleteCard(
		cardId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public cardsControllerDeleteCard(
		cardId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public cardsControllerDeleteCard(
		cardId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public cardsControllerDeleteCard(
		cardId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (cardId === null || cardId === undefined) {
			throw new Error('Required parameter cardId was null or undefined when calling cardsControllerDeleteCard.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/cards/${this.configuration.encodeParam({
			name: 'cardId',
			value: cardId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param cardId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public cardsControllerGetCard(
		cardId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public cardsControllerGetCard(
		cardId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public cardsControllerGetCard(
		cardId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public cardsControllerGetCard(
		cardId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (cardId === null || cardId === undefined) {
			throw new Error('Required parameter cardId was null or undefined when calling cardsControllerGetCard.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/cards/${this.configuration.encodeParam({
			name: 'cardId',
			value: cardId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param userId
	 * @param linkId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public cardsControllerGetCardList(
		userId: string,
		linkId?: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Array<NfcCard>>;
	public cardsControllerGetCardList(
		userId: string,
		linkId?: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Array<NfcCard>>>;
	public cardsControllerGetCardList(
		userId: string,
		linkId?: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Array<NfcCard>>>;
	public cardsControllerGetCardList(
		userId: string,
		linkId?: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling cardsControllerGetCardList.');
		}

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (userId !== undefined && userId !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>userId, 'userId');
		}
		if (linkId !== undefined && linkId !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>linkId, 'linkId');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/cards`;
		return this.httpClient.request<Array<NfcCard>>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			params: localVarQueryParameters,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param requestBody
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public cardsControllerPreRegisterCard(
		requestBody: Array<string>,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public cardsControllerPreRegisterCard(
		requestBody: Array<string>,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public cardsControllerPreRegisterCard(
		requestBody: Array<string>,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public cardsControllerPreRegisterCard(
		requestBody: Array<string>,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (requestBody === null || requestBody === undefined) {
			throw new Error(
				'Required parameter requestBody was null or undefined when calling cardsControllerPreRegisterCard.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/cards/pre-register`;
		return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: requestBody,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}
}
