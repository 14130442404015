import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tagAttribute',
})
export class TagAttributePipe implements PipeTransform {
	transform(tagNum: number, tags: any, attribute: string): string | number | undefined {
		return tags.find((x: any) => x.id === tagNum)?.[attribute];
	}
}
